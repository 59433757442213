export const tvlUsdIntervals = [
  { extremes: [0, 100000], id: 0 },
  { extremes: [100000, 1000000], id: 1 },
  { extremes: [1000000, 5000000], id: 2 },
  { extremes: [5000000, 20000000], id: 3 },
  { extremes: [20000000, 999999999999999], id: 4 },
];
export const apyBase1dIntervals = [
  { extremes: [0, 5], id: 0 },
  { extremes: [5, 20], id: 1 },
  { extremes: [20, 100], id: 2 },
  { extremes: [100, 500], id: 3 },
  { extremes: [500, 999999999999999], id: 4 },
];
export const apyBase30dIntervals = [
  { extremes: [0, 5], id: 0 },
  { extremes: [5, 20], id: 1 },
  { extremes: [20, 100], id: 2 },
  { extremes: [100, 500], id: 3 },
  { extremes: [500, 999999999999999], id: 4 },
];