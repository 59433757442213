import { Helmet } from "react-helmet";
import { DexesOveriew } from "../components/containers/homepage/DexesOverview";
import { Dune } from "../components/containers/homepage/Dune";
import { Inflows } from "../components/containers/homepage/Inflows";
import { ProjectOfTheDay } from "../components/containers/homepage/ProjectOfTheDay";
import { TokenOverview } from "../components/containers/homepage/TokenOverview";
import { HomeHeader } from "../components/homepage/HomeHeader";
import Loader from "../components/Loader";

import { useGetHomeData } from "../hooks/use-get-home-data";

function HomePage() {
  const { loading, homeData } = useGetHomeData();

  return (
    <div className="w-full h-fit min-h-screen flex items-center pt-16 pb-5">
      <Helmet>
        <title>$ARMY | Web3 Market Intelligence & Analytics Platform</title>
        <meta
          name="description"
          content="Access professional-grade crypto analytics, P/E analysis, burn tracking, and yield farming tools. Join the leading web3 intelligence platform powered by $ARMY token."
        />
      </Helmet>
      <div className="w-full flex flex-col items-center relative">
        {/* Show loader while data is being fetched */}
        {loading && (
          <div className="w-full flex items-center justify-center flex-grow">
            <Loader />
          </div>
        )}

        {/* Render HomeHeader if data is available */}
        {homeData && !loading && <HomeHeader data={homeData} />}

        {/* Render main content sections */}
        <div className="flex flex-col gap-y-10 2xl:grid 2xl:grid-cols-2 w-full">
          <Inflows />
          <ProjectOfTheDay />
          <Dune />
          <DexesOveriew />
          <div className="w-full">
            <TokenOverview />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
