export const BEErrorBanner = () => {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full h-[90%] bg-ui-black rounded-md z-50 border border-neutral-300/30 flex flex-col items-center justify-center">
      <img
        src="/black-logo.webp"
        alt="bc logo"
        className="pointer-events-none h-[90%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10 "
      />
      <div className="text-2xl ">We're victim of our own success.</div>
      <div className="text-2xl text-center">
        Too many people. 
      </div>
      <div className="text-lg text-center">
      (Or, more likely, our devs broke something...)
      </div>
      <div className="text-3xl text-red-700"> We'll be back online soon.</div>
    </div>
  );
};
