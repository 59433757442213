

const WalletAndAuth = () => {
  return (
    <div className="">
      <div className="w3-button !uppercase mr-2">
        <appkit-button balance="show" label="CONNECT WALLET" />
      </div>
    </div>
  );
};

export default WalletAndAuth;
