import { Helmet } from "react-helmet";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import { BestFarmsWrapper } from "../components/containers/BestFarms/BestFarmsWrapper";
export default function BestFarms() {
  return (
    <main className="w-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <Helmet>
        <title>Best Yield Farms | DeFi Yield Aggregator & Analytics</title>
        <meta
          name="description"
          content="Find the most profitable yield farming opportunities across multiple chains. Real-time APR tracking, risk assessment, and impermanent loss projections."
        />
      </Helmet>
      <AuthenticatedContainer>
        <BestFarmsWrapper />
      </AuthenticatedContainer>
    </main>
  );
}
