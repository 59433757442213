export const BCAFeedback = ({feedback}) => {
  return (
    <div className="w-full border border-neutral-300/20  p-2.5 rounded-sm mb-5">
      <div className="text-md mb-2 w-full border-b border-b-neutral-300/30">
        Army Feedback
      </div>
      <p className="text-sm font-mono">{feedback ? feedback : <span className="font-mono">NO DATA</span>}</p>

    </div>
  );
};
