import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../../Loader";
const ProjectKpi = ({ label, value }) => {
  return (
    <div className="w-full py-2.5 2xl:py-1 flex flex-col items-center justify-center bg-ui-black rounded-md">
      <div className="text-white/50 mb-1 uppercase text-sm 2xl:text-[8px] text-center whitespace-nowrap">
        {label}
      </div>
      <div className="text-white font-bold text-lg 2xl:text-xs">{value}</div>
    </div>
  );
};
export const ProjectOfTheDay = () => {
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  useEffect(() => {
    const savedProjectData = localStorage.getItem("project-data");

    const fetchDataAndStore = async () => {
      try {
        const response = await axios
          .get("https://bcarmy-temp.vercel.app/api/get-project-day-data")
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        const dataToStore = response;
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 3);
        localStorage.setItem(
          "project-data",
          JSON.stringify({
            data: dataToStore,
            expiration: expirationTime.getTime(),
          })
        );
        setProjectData(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (!savedProjectData) {
      setLoading(true);
      fetchDataAndStore();
    } else if (savedProjectData && !JSON.parse(savedProjectData).data) {
      localStorage.removeItem("project-data");
      setLoading(true);
      fetchDataAndStore();
    } else {
      const currentTime = new Date().getTime();
      const savedExpirationTime = JSON.parse(savedProjectData).expiration;
      if (currentTime > savedExpirationTime) {
        localStorage.removeItem("project-data");
        setLoading(true);
        fetchDataAndStore();
      } else {
        setProjectData(JSON.parse(savedProjectData).data);
        setLoading(false);
      }
    }
  }, []);
  return (
    <div className="w-full md:h-[calc(100vh-160px)] 2xl:h-[calc(60vh-160px)] 2xl:min-h-[450px] px-5 flex flex-col max-2xl:mt-5">
      <div className="oswald text-xl ">PROJECT OF THE WEEK - BANANA</div>
      <div className="oswald text-sm 2xl:text-xs pb-2.5">
        Handpicked project by our analysts (best R/R){" "}
      </div>
      <div className="flex flex-col md:flex-row 2xl:flex-col gap-x-5  md:h-[calc(100%-40px)] ">
        <iframe
          className="grow h-[50vh] max-md:w-full 2xl:w-full 2xl:h-[70%] rounded-sm"
          id="dextools-widget"
          title="DEXTools Trading Chart"
          width="500"
          height="400"
          src="https://www.dextools.io/widget-chart/en/ether/pe-light/0x43de4318b6eb91a7cf37975dbb574396a7b5b5c6?theme=dark&chartType=1&chartResolution=30&drawingToolbars=false&headerColor=333333"
        ></iframe>
        <div className="w-full md:w-1/4 h-auto 2xl:w-full 2xl:h-[30%]">
          {!loading && projectData ? (
            <div className="w-full bg-black rounded-sm h-fit grid grid-cols-2 2xl:grid-cols-8 p-2.5 gap-2.5">
              <ProjectKpi
                label="Market cap"
                value={"$" + (projectData.mktcap / 1_000_000).toFixed(2) + "M"}
              />
              <ProjectKpi
                label="Liquidity"
                value={
                  "$" + (projectData.liquidity / 1_000_000).toFixed(2) + "M"
                }
              />
              <ProjectKpi
                label="Circ Supply"
                value={projectData.circulatingSupply / 1_000 + "K"}
              />
              <ProjectKpi
                label="Holders"
                value={projectData.holders / 1_000 + "K"}
              />
              <ProjectKpi
                label="Total MKCAP"
                value={
                  "$" + (projectData.totalMktcap / 1_000_000).toFixed(2) + "M"
                }
              />
              <ProjectKpi
                label="24H Volume"
                value={
                  "$" + (projectData.volume24h / 1_000_000).toFixed(2) + "M"
                }
              />
              <ProjectKpi
                label="Total Supply"
                value={(projectData.totalSupply / 1_000_000).toFixed(2) + "M"}
              />
              <div className="w-full py-2.5 2xl:py-1  flex flex-col items-center justify-center bg-ui-black rounded-md">
                <div className="text-sm 2xl:text-[8px] text-white/50 mb-1 uppercase text-center whitespace-nowrap">
                  % CIRC. SUPPLY
                </div>
                <div className="flex flex-row 2xl:flex-col items-center w-full px-3 gap-x-2">
                  <div className="text-white font-bold text-lg 2xl:text-xs">
                    {(
                      (projectData.circulatingSupply /
                        projectData.totalSupply) *
                      100
                    ).toFixed()}
                    %
                  </div>
                  <div className="h-1 grow bg-white/80 rounded-sm relative">
                    <div
                      className="h-full bg-ui-red rounded-sm absolute left-0"
                      style={{
                        width:
                          (projectData.circulatingSupply /
                            projectData.totalSupply) *
                            100 +
                          "%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
          <div className="w-full  rounded-sm h-fit mt-2.5 2xl:mt-1 p-2.5 2xl:p-1">
            <div className="font-bold oswald text-xl 2xl:text-xs">
              Why do we like Banana?
            </div>
            <div className="text-sm 2xl:text-[12px] mt-1 leading-none">
            Real revenue that gives you APY, growth potential, catalyst incoming, fun team.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
