import PeLinechart from "./PeLinechart";
import PeSingleProjectInfos from "../../PE/PeSingleProjectInfos";
import { FaChevronLeft } from "react-icons/fa6";
import TwoColsDescription from "../../TwoColsDescription";
import PeRevenues from "./PeRevenues";
import { BEErrorBanner } from "../../BEErrorBanner";
import { BCAFeedback } from "./BCAFeedback";
import { Helmet } from "react-helmet";
import ShareOnXButton from "../../ShareOnX";

const PeSingleElementCharts = ({
  id,
  goBack,
  extraInfos,
  tokenData,
  holdersRevText,
  projectRevText,
  graphInfo,
}) => {
  if (!tokenData?.projectInfo.name) {
    return (
      <div className="w-full pb-5 h-full max-md:h-full max-md:max-h-screen flex items-center justify-center relative">
        <BEErrorBanner />
      </div>
    );
  }
  function capitalizeFirstLetter(val) {
    if(!val) return ''
    if(val?.length === 0) return ''
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}
  const tokenName = tokenData.projectInfo?.name;
  return (
    <div className="w-full pb-5 max-md:h-full max-md:max-h-screen pr-2 overflow-y-auto relative">
      <Helmet>
        <title>{`${capitalizeFirstLetter(tokenName)} P/E Analysis | Detailed Revenue & Valuation Metrics`}</title>
        <meta
          name="description"
          content={`In-depth ${capitalizeFirstLetter(tokenName)}  analysis with real-time P/E ratios, revenue data, market metrics, and historical performance. Make data-driven investment decisions.`}
        />
      </Helmet>
      <ShareOnXButton token={tokenData.projectInfo?.name} pageUrl={window.location.href} />
      <div
        className="flex flex-row items-center hover:text-ui-red cursor-pointer text-sm"
        onClick={goBack}
      >
        <FaChevronLeft /> Go back
      </div>
      {tokenData?.projectInfo ? (
        <div className="w-full pt-5">
          <div className="text-xl mb-1.5 uppercase">
            {tokenData.projectInfo?.name}
          </div>
          {tokenData.projectInfo?.description?.length ? (
            <div className="text-xs mb-2.5 text-white/70">
              {tokenData.projectInfo.description}
            </div>
          ) : null}
          <div className="h-[40vh] w-full relative">
            <img
              src="/black-logo.webp"
              alt="bc logo"
              className="pointer-events-none h-[70%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
            />
            <PeLinechart numbers={graphInfo} />
          </div>
          <div className="flex flex-col md:flex-row w-full mt-5 gap-x-5 md:gap-x-10">
            <div className="w-full md:w-[40%]">
              <PeSingleProjectInfos
                extraInfos={extraInfos}
                infos={tokenData.projectInfo}
                lastPrice={
                  graphInfo[graphInfo.length - 1]?.price
                    ? graphInfo[graphInfo.length - 1]?.price
                    : 0
                }
              />
            </div>
            <div className="w-full mt-5 md:mt-0 pb-10 md:pb-0">
              <PeRevenues numbers={extraInfos} />
              {/* <TokenUnlock info={singlePe.projectInfo.tokenFake} /> */}
              {tokenData?.projectInfo?.feedback && (
                <BCAFeedback feedback={tokenData?.projectInfo?.feedback} />
              )}
              <TwoColsDescription
                textHolders={holdersRevText}
                textProject={projectRevText}
                token={id}
              />
            </div>
          </div>
          <div className="mt-10 text-xs text-neutral-300/70">
            Data from{" "}
            <a
              href=" https://www.coingecko.com/"
              target="_blank"
              rel="noreferrer"
              className="text-red-800 underline"
            >
              Coingecko
            </a>{" "}
            and{" "}
            <a
              href="https://defillama.com/"
              target="_blank"
              rel="noreferrer"
              className="text-red-800 underline"
            >
              Defillama
            </a>
          </div>
        </div>
      ) : (
        <div className="relative w-full h-[80vh] bg-ui-black rounded-md z-50 border border-neutral-300/30 flex flex-col items-center justify-center">
          <img
            src="/black-logo.webp"
            alt="bc logo"
            className="pointer-events-none h-[90%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10 "
          />
          <div className="text-2xl">We encountered an error.</div>
          <div className="text-3xl text-red-700"> We'll fix it soon.</div>
        </div>
      )}
    </div>
  );
};
export default PeSingleElementCharts;
