/* const oswald = Oswald({ subsets: ["latin"] });
 */
const OnlyLogged = () => {
  return (
    <div
      className={`bg-ui-dark-gray rounded-[4px] flex flex-col items-center justify-center md:h-[30vh] md:w-[50vw] p-10 md:p-20 mx-auto`}
    >
      <h2 className="oswald text-xl md:text-3xl text-center uppercase">
        This section is available only for logged user.
      </h2>
      <h3 className="hidden md:block text-xl mt-5 text-center">
        Click on &quot;Connect&quot; to connect your wallet and LogIn
      </h3>
      <div className="md:hidden text-xl mt-5 text-center flex flex-row flex-wrap justify-center">
        <span className="flex flex-row flex-wrap justify-center gap-x-1">
          Tap on <span className="uppercase font-bold">Connect wallet</span> in the header to LogIn
        </span>{" "}
      </div>
    </div>
  );
};
export default OnlyLogged;
