import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, /* useReadContract, useDisconnect */ } from "wagmi";

import { createAppKit } from "@reown/appkit/react";
import { mainnet } from "@reown/appkit/networks";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";

const isDev =
Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;

const root = ReactDOM.createRoot(document.getElementById("root"));

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "9f0339de069cf955833797020740df8c";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: isDev ? "https://dev-app-army.netlify.app" : "https://app.bc.army", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet];

const wagmiAdapter = new WagmiAdapter({
  networks: chains,
  projectId,
});

createAppKit({
  adapters: [wagmiAdapter],
  networks: [mainnet],
  metadata: metadata,
  projectId,
  features: {
    analytics: false,
    email: false,
    socials: false,
    emailShowWallets: false
  },
  themeVariables: {
    "--w3m-font-family": "Oswald, sans-serif",
    "--w3m-accent": "#D22326",
    "--w3m-color-mix": "#262626",
    "--w3m-color-mix-strength": 70,
    "--w3m-border-radius-master": "2px",
  },
  tokens: {
    "eip155:1": {
      address: "0x294d44386c9ff8c38659511297a2e08d829f9336",
    },
  },
});

root.render(
  <React.StrictMode>
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

