export const GenericInput = ({ label, action, type, min, value, active, thin }) => {
  const handleChange = (e) => {
    action(e.target.value);
  };

  return (
    <div className="w-full">
      <input
        onChange={handleChange}
        placeholder={label}
        className={`flex
          ${thin ? 'h-[34px]' : 'h-10'}
          w-full 
          rounded-md 
          border 
          border-neutral-300/20  
          bg-neutral-900 
          px-3 py-2 text-sm 
          placeholder:text-muted-foreground
          focus-visible:outline-none 
          focus-visible:ring-1
          focus-visible:ring-ring 
          focus-visible:ring-offset-1 
          disabled:cursor-not-allowed 
          disabled:opacity-50
          ${active ? 'ring-1 ring-ring ring-white': ''}`}
        type={type ? type : "text"}
        min={min ? min : null}
        value={value}
      />
    </div>
  );
};
