import { useEffect, useState } from "react";
import axios from "axios";
const isDev =
Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
function filterDataByDate(dataArray) {
  const targetDate = new Date("2024-09-19T00:00:00Z");
  return dataArray.filter((item) => new Date(item.date) > targetDate);
}

export const useGetTokenData = (id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTexts, setIsLoadingTexts] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const [holdersRevText, setHolderRevText] = useState(null);
  const [projectRevText, setProjectRevText] = useState(null);
  const [graphInfo, setGraphInfo] = useState([]);

  useEffect(() => {
    setIsLoadingTexts(true);
    axios
      .get(`https://api.llama.fi/summary/fees/${id}?dataType=dailyFees`)
      .then(function (response) {
        // handle success
        if (response?.data?.methodology) {
          if (response?.data?.methodology?.HoldersRevenue) {
            setHolderRevText(response.data.methodology.HoldersRevenue);
          }
          if (response?.data?.methodology?.ProtocolRevenue) {
            setProjectRevText(response.data.methodology.ProtocolRevenue);
          }
        }
        setIsLoadingTexts(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoadingTexts(false);
      });
  }, [id]);
  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    axios
      .get(`https://${isDev ? "dev" : "api"}.bc.army/api/pe-data/${id}/`)
      .then(function (response) {
        // handle success
        setGraphInfo(filterDataByDate(response.data.graphInfo));
        setTokenData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);
  return {
    isLoading,
    isLoadingTexts,
    tokenData,
    holdersRevText,
    projectRevText,
    graphInfo,
  };
};
