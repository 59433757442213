import LinkButton from "./LinkButton";
import {
  Army,
  BurnIcon,
  PEAnalysis,
  BestFarms,
  ChadIcon,
  WIP,
} from "./icons/NavIcons";
import { useEffect, useState } from "react";
export const navigationSettings = [
  {
    label: "Overview",
    path: "/",
    icon: <Army />,
  },
  {
    label: "P/E Analysis",
    path: "/pe-analysis",
    icon: <PEAnalysis />,
  },
  {
    label: "Best Farms",
    path: "/best-farms",
    icon: <BestFarms />,
  },
  {
    label: "Burn Dashboards",
    path: "/burn-dashboards",
    subRoutes: [{}],
    icon: <BurnIcon />,
  },
  {
    label: "ChadGPT",
    isWip: true,
    path: "/chadgpt",
    disabled: true,
    icon: <ChadIcon />,
  },
  {
    label: "Staking",
    isWip: true,
    path: "/staking",
    disabled: true,
    icon: <WIP />,
  },
  {
    label: "Holder analysis bot",
    isWip: true,
    path: "/holders-analysis",
    disabled: true,
    icon: <WIP />,
  },
  {
    label: "Access telegram bot",
    isWip: true,
    path: "/t-bot",
    disabled: true,
    icon: <WIP />,
  },
  {
    label: "Basis Trading",
    isWip: true,
    path: "/basis",
    disabled: true,
    icon: <WIP />,
  },
];

const Navigation = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    // Ascolta i cambiamenti di history (se la tua app li supporta)
    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);
  return (
    <div className="w-full pl-5 pr-5 flex flex-col gap-y-2 max-md:mt-5">
      {navigationSettings.map((el) => {
        return (
          <LinkButton
            key={el.label}
            linkPath={el.path}
            label={el.label}
            icon={el.icon}
            isActive={currentPath === el.path}
            isWip={el.isWip ? el.isWip : false}
            disabled={el.disabled ? el.disabled : false}
          />
        );
      })}
    </div>
  );
};

export default Navigation;
