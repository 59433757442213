import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Loader from "../../Loader";
import PeTable from "../PeTable";
import { useGetPeData } from "../../../hooks/use-get-pe-data";
import { useNavigate } from "react-router-dom";
import { BEErrorBanner } from "../../BEErrorBanner";
import { FourtFav } from "../../PE/FourFav";
import { useGetPeFourToken } from "../../../hooks/use-get-pe-four-token";
ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function generateChartData(labels, dataset) {
  return {
    labels,
    datasets: [
      {
        label: "P/E Ratio",
        data: dataset.map((e) => e.value),
        backgroundColor: "#D22326",
        borderSkipped: "bottom",
        borderRadius: 8,
        /*  parsing: {
          yAxisKey: 'peRatio'
        } */
      },
    ],
  };
}

function PEMainCharts() {
  const navigate = useNavigate();

  const [selectedData, setSelectedData] = useState(null);
  const [enrichedSelected, setEnrichedSelected] = useState(null);

  const { peData, labels, sortedPeData, loading, backendError } =
    useGetPeData();
  const {fourToken, loading: fourTokenLoading} =useGetPeFourToken()
  const handleDataSelection = (id) => {
    console.log(id)
    if (!peData) return;
    navigate(`/pe-analysis/${id}`);
    /* const allInfos = peData.find((e) => e.id === id);
    setEnrichedSelected(allInfos ?? null);
    setSelectedData(id); */
  };
  const handleGoBack = () => {
    setSelectedData(null);
    setEnrichedSelected(null);
  };

  const peChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label +=
              (context.raw * 100) / 100 >= 99
                ? "99+"
                : Math.round(context.raw * 100) / 100;
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        type: "logarithmic",
        grid: {
          color: "#383838",
        },
        ticks: {
          color: "#e0e0e0",
        },
      },
      x: {
        grid: {
          color: "transparent",
        },
        ticks: {
          color: "#e0e0e0",
        },
      },
    },
    onClick: function (evt, element) {
      if (element.length > 0) {
        handleDataSelection(sortedPeData[element[0].index].id);
      }
    },
    onHover: (event, chartElement) => {
      if (chartElement.length === 1) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },
  };

  return (
    <div className={` w-full flex flex-col flex-grow relative pt-10 md:pt-16`}>
      <div className="mb-5">
        <h2 className="text-[22px] text-neutral-200 font-semibold uppercase">
          The P/E Dashboard
        </h2><p className="text-sm text-neutral-300/70 mb-2">
          In crypto, the P/E ratio measures a token's price relative to its
          earnings per token. A high P/E can indicate overvaluation or high
          growth expectations, while a low P/E may signal undervaluation or
          limited growth prospects.
        </p>
        {fourTokenLoading && <div className="w-full flex items-center justify-center"><Loader /></div>}
        {fourToken?.coins.length >0 && <FourtFav data={fourToken.coins}/>}
        
      </div>
      {backendError && <BEErrorBanner />}
      {loading ? (
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">Loading Data...</div>
          </div>
        </div>
      ) : null}
      {peData && labels && sortedPeData && !loading ? (
        <div
          className="w-full"
          style={{ display: selectedData ? "none" : "block" }}
        >
          <div className="w-full border border-neutral-300/20  rounded-sm p-5 h-[60vh] relative z-20">
            <img
              src="/black-logo.webp"
              alt="bc logo"
              className="pointer-events-none h-[70%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10 z-10"
            />

            <Bar
              options={peChartoptions}
              data={generateChartData(labels, sortedPeData)}
            />
          </div>
          <PeTable dataset={peData} action={handleDataSelection} />
          <div className="mt-2 mb-5 text-xs text-neutral-300/70">
            Data from{" "}
            <a
              href=" https://www.coingecko.com/"
              target="_blank"
              rel="noreferrer"
              className="text-red-800 underline"
            >
              Coingecko
            </a>{" "}
            and{" "}
            <a
              href="https://defillama.com/"
              target="_blank"
              rel="noreferrer"
              className="text-red-800 underline"
            >
              Defillama
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default PEMainCharts;
