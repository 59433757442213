import { useNavigate } from "react-router-dom";

const mock = [
  {
    id: "dextools",
    name: "dextools",
    logoUrl:
      "https://coin-images.coingecko.com/coins/images/11603/large/dext.png?1696511498",
    marketCap: 43440350,
    fullyDilutedMarketCap: 66728168,
    fullyDilutedMarketCapRatio: 0.651004685157848,
    revenues: {
      revenue1day: 29092,
      revenue1week: 266157,
      revenue1month: 1219165,
      revenue1year: 4382930,
    },
    holdersRevenue: 0,
    cumulativeRevenue: 4424892,
    peRatio: 2.96926926762716,
  },
  {
    id: "solana",
    name: "solana",
    logoUrl:
      "https://coin-images.coingecko.com/coins/images/4128/large/solana.png?1718769756",
    marketCap: 122441053403,
    fullyDilutedMarketCap: 151869736580,
    fullyDilutedMarketCapRatio: 0.806224177115775,
    revenues: {
      revenue1day: 12516721,
      revenue1week: 73360371,
      revenue1month: 159420956,
      revenue1year: 525350963,
    },
    holdersRevenue: 0,
    cumulativeRevenue: 608759267,
    peRatio: 64.003010475716,
  },
  {
    id: "smardex",
    name: "smardex",
    logoUrl:
      "https://coin-images.coingecko.com/coins/images/29470/large/SDEX_logo_transparent_outside_240x240.png?1696930070",
    marketCap: 120148576,
    fullyDilutedMarketCap: 134207484,
    fullyDilutedMarketCapRatio: 0.895244977545366,
    revenues: {
      revenue1day: 1402,
      revenue1week: 7410,
      revenue1month: 29193,
      revenue1year: 566904,
    },
    holdersRevenue: 0,
    cumulativeRevenue: 693150,
    peRatio: 99,
  },
  {
    id: "pangolin",
    name: "pangolin",
    logoUrl:
      "https://coin-images.coingecko.com/coins/images/14023/large/PNG_token.png?1696513750",
    marketCap: 46697848,
    fullyDilutedMarketCap: 115047788,
    fullyDilutedMarketCapRatio: 0.405899572793177,
    revenues: {
      revenue1day: 1513,
      revenue1week: 12695,
      revenue1month: 43796,
      revenue1year: 2971176,
    },
    holdersRevenue: 0,
    cumulativeRevenue: 53627888,
    peRatio: 88.8548573690139,
  },
];
const FavCard = ({ infos, i }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/pe-analysis/${infos.gecko_id}`);
  };
  return (
    <div
      className={`w-full font-mono text-sm cursor-pointer ${
        i < 3 && "md:border-r md:border-r-neutral-300/20"
      } ${i % 2 === 0 && "max-md:border-r max-md:border-r-neutral-300/20"}  ${
        i >1 && "max-md:border-t max-md:border-t-neutral-300/20"
      }`}
      onClick={() => handleClick()}
    >
      <div className="flex flex-row justify-between w-full border-b border-neutral-300/20 p-2">
        {infos.name}{" "}
        {infos.logoUrl ? (
          <img
            src={infos.logoUrl}
            alt="Logo"
            className="h-5 w-5 rounded-full"
          />
        ) : (
          <div className="h-5 w-5 bg-red-700 rounded-full"></div>
        )}
      </div>
      <div className="p-2">
        <div className="flex flex-row justify-between">
          <div className="text-xs">PE 30d</div>{" "}
          <div className="font-semibold">{infos.pe_ratio.toFixed(2)}</div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-xs">Revenues 30d</div>{" "}
          <div className="font-semibold">
            ${infos.revenue_30d.toLocaleString()}
          </div>
        </div>
        {infos.feedback && (
          <div className="flex flex-col  text-xs mt-2">
            <div>{infos.feedback}</div>
          </div>
        )}
      </div>
    </div>
  );
};
export const FourtFav = ({ data }) => {
  return (
    <div className="w-full mt-6 mb-2.5">
      <div className="text-md mb-1">On our watchlist</div>
      <div className="grid grid-cols-2 md:grid-cols-4 rounded-md border border-neutral-300/20">
        {data.map((infos, i) => (
          <FavCard infos={infos} key={infos.id} i={i} />
        ))}
      </div>
    </div>
  );
};
