import React from "react";
import { FaXTwitter } from "react-icons/fa6";

const ShareOnXButton = ({ token, pageUrl }) => {
  const tweetText = `P/E of ${token} looks interesting. Found on @blockchainarmy_`;
  const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    tweetText
  )}&url=${encodeURIComponent(pageUrl)}`;

  return (
    <button
      onClick={() => window.open(shareUrl, "_blank")}
      className="fixed top-[100px] right-14 z-50 bg-ui-red text-white font-mono rounded-md px-4 py-2 hover:bg-ui-red/90 transition-all duration-300 ease-in-out flex flex-row items-center text-sm gap-x-2"
    >
      Share on <FaXTwitter />
    </button>
  );
};

export default ShareOnXButton;
