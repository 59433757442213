import { useState, useEffect } from "react";
import { GenericInput } from "../GenericInput";
import { LuFilterX } from "react-icons/lu";
import DataTable from "react-data-table-component";
import { ColTitle } from "./PE/PeColumns";
import { useNavigate } from "react-router-dom";

const PeTable = ({ dataset, action }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [showFilters, setShowfilters] = useState(true);
  const [tokenName, setTokenName] = useState("");
  const [minPe, setMinPe] = useState(null);
  const [minMK, setMinMK] = useState(null);
  const navigate = useNavigate();
  const handleClick = (row) => {
    console.log(row)
    navigate(`/pe-analysis/${row.id}`);
  };
  const columnsPe = [
    {
      name: "Name",
      selector: (row) => row.name,
      format: (row) => (
        <div
          className="cursor-pointer w-full flex items-center flex-row gap-x-2  py-1 overflow-hidden whitespace-nowrap"
          onClick={() => handleClick(row)}
        >
          <img src={row.logoUrl} alt="Logo" className="h-7 w-7 rounded-full" />
          <span className=" font-medium capitalize">{row.name}</span>
        </div>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: <ColTitle name="PE (daily)" />,
      selector: (row) => row.peDaily,
      sortable: true,
      format: (row) =>
        `${
          row.peDaily < 0
            ? "1000+"
            : row.peDaily >= 99
            ? "99+"
            : row.peDaily.toLocaleString()
        }`,
      minWidth: "140px",
      right: true,
    },
    {
      name: <ColTitle name="PE (weekly)" />,
      selector: (row) => row.peWeekly,
      sortable: true,
      format: (row) =>
        `${
          row.peWeekly < 0
            ? "1000+"
            : row.peWeekly >= 99
            ? "99+"
            : row.peWeekly.toLocaleString()
        }`,
      minWidth: "140px",
      right: true,
    },
    {
      name: <ColTitle name="PE (monthly)" />,
      selector: (row) => row.peRatio,
      sortable: true,
      format: (row) =>
        `${
          row.peRatio < 0
            ? "1000+"
            : row.peRatio >= 99
            ? "99+"
            : row.peRatio.toLocaleString()
        }`,
      minWidth: "140px",
      right: true,
    },
    {
      name: <ColTitle name="Market Cap" />,
      selector: (row) => row.marketCap,
      sortable: true,
      format: (row) =>
        `${row.marketCap ? "$" + row.marketCap.toLocaleString() : "$0"}`,
      minWidth: "160px",
      right: true,
    },
    {
      name: <ColTitle name="Revenue (24h)" />,
      selector: (row) => row.revenue1day,
      sortable: true,
      format: (row) =>
        `${
          row.revenue1day
            ? "$" + Math.floor(row.revenue1day).toLocaleString()
            : "$0"
        }`,
      minWidth: "140px",
      right: true,
    },
    {
      name: <ColTitle name="Revenue (7d)" />,
      selector: (row) => row.revenue1week,
      sortable: true,
      format: (row) =>
        `${
          row.revenue1week
            ? "$" + Math.floor(row.revenue1week).toLocaleString()
            : "$0"
        }`,
      minWidth: "140px",
      right: true,
    },
    {
      name: <ColTitle name="Revenue (30d)" />,
      selector: (row) => row.revenue1month,
      sortable: true,
      format: (row) =>
        `${
          row.revenue1month
            ? "$" + Math.floor(row.revenue1month).toLocaleString()
            : "$0"
        }`,
      minWidth: "140px",
      right: true,
    },
    {
      name: <ColTitle name="mc/fdv" />,
      selector: (row) => row.fullyDilutedMarketCapRatio,
      sortable: true,
      format: (row) =>
        `${
          row.fullyDilutedMarketCapRatio
            ? row.fullyDilutedMarketCapRatio.toFixed(5)
            : "0"
        }`,
      minWidth: "140px",
      right: true,
    },
  ];
  const resetFilters = () => {
    setTokenName("");
    setMinPe(null);
    setMinMK(null);
  };
  useEffect(() => {
    const filtered = dataset.filter((item) => {
      const matchesTokenName =
        tokenName.length === 0 ||
        item.name.toLowerCase().includes(tokenName.toLowerCase());

      const matchesMinPe = minPe <= 0 || item.peRatio <= minPe;
      const matchesMinMK = minMK <= 0 || item.marketCap >= Number(minMK);
      const noEarnings =
        item.revenue1day === 0 &&
        item.revenue1week === 0 &&
        item.revenue1month === 0;
      const hasId = item.id !== undefined && item.id !== null && item.id !== "" && item.id !== '-';
      return matchesTokenName && matchesMinPe && matchesMinMK && !noEarnings && hasId;
    });
    setFilteredData(filtered);
  }, [tokenName, minPe, minMK, dataset]);
  return (
    <div className="w-full mt-10">
      <div className="w-full border border-neutral-300/20  p-2 rounded-sm ">
        <div
          className={`flex flex-col w-full ${
            showFilters ? "h-auto" : "h-0"
          } overflow-hidden transition-all duration-300`}
        >
          <div
            className={`w-full flex flex-col md:grid md:grid-cols-5 gap-x-2 gap-y-2 items-start md:items-center`}
          >
            <div className="text-sm">Filter by:</div>
            <GenericInput
              label={"Token name"}
              action={setTokenName}
              value={tokenName}
              active={tokenName?.length > 0}
            />
            <GenericInput
              label={"PE Value (<= than)"}
              action={setMinPe}
              type="number"
              min="0"
              value={minPe}
              active={minPe > 0}
            />
            <GenericInput
              label={"Market cap (>= than)"}
              action={setMinMK}
              type="number"
              min="0"
              value={minMK}
              active={minMK > 0}
            />
            <div className="flex flex-row items-center gap-x-2 justify-end">
              <div className="text-xs">Reset filters</div>{" "}
              <button
                className="p-1 border border-neutral-300/20  rounded-sm hover:bg-red-500/80"
                onClick={() => resetFilters()}
              >
                <LuFilterX />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-2 border border-neutral-300/20 rounded-sm flex flex-col overflow-auto h-fit relative">
        <img
          src="/black-logo.webp"
          alt="bc logo"
          className=" pointer-events-none w-[70%] absolute left-1/2 top-1/3 md:top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
        />
        {filteredData && (
          <DataTable
            columns={columnsPe}
            data={filteredData}
            pagination
            fixedHeader={true}
            theme="armyTheme"
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 250]}
          />
        )}
      </div>
      {/* <div
        className="w-full border border-neutral-300/20  mt-2.5 mb-[40px] rounded-sm max-md:max-w-full max-md:overflow-x-auto bg-contain	bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/black-logo.webp)`,
        }}
      >
        <PeTableHeader clickedSort={handleSorting} selectedId={sortBy} />
        <div className="w-full h-fit max-h-[50vh] overflow-y-auto">
          {sortObjectsArray(filteredData, sortBy, isDescending).map((data, i) => (
            <PeTableRow infos={data} key={data.name + '-'+i} action={action} />
          ))}
        </div>
      </div> */}
    </div>
  );
};
export default PeTable;
