export const ColTitle = ({ name, isBold, showInfo }) => {
  return (
    <div
      className={`flex relative justify-center w-full ${
        isBold && "font-bold"
      } ${showInfo && "space-between"}`}
    >
      {name}
    </div>
  );
};
