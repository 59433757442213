import { useParams } from "react-router-dom";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import { PeTokenWrapper } from "../components/containers/PEToken/PeTokenWrapper";
export default function PEAnalysisToken() {
  const params = useParams();

  return (
    <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-20">
      <AuthenticatedContainer>
        <PeTokenWrapper tokenId={params.tokenId} />
      </AuthenticatedContainer>
    </main>
  );
}
