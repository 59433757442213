import DataTable, { createTheme } from "react-data-table-component";

import { useEffect, useState } from "react";
import Loader from "../../Loader";
import { useGetDuneData } from "../../../hooks/use-get-dune-data";
import { ColTitle } from "../PE/PeColumns";
createTheme(
  "armyTheme",
  {
    text: {
      primary: "#fff",
      secondary: "#d4d4d4",
      fontFamily: "Roboto Mono, monospace",
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#bf2123",
      text: "#FFFFFF",
    },
    divider: {
      default: "#d4d4d433",
    },
    button: {
      default: "#bf2123",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "dark"
);

const columns = [
  {
    name: "Project",

    selector: (row) => row.project,
    format: (row) => (
      <div className="cursor-pointer w-full flex items-center flex-row gap-x-2  py-1 overflow-hidden whitespace-nowrap">
        <span className=" font-medium capitalize">{row.project}</span>
      </div>
    ),
    sortable: true,
    minWidth: "200px",
  },
  {
    name: <ColTitle name="7D Volume" />,
    selector: (row) => row.seven_day_volume,
    sortable: true,
    format: (row) => `$${row.seven_day_volume?.toLocaleString()}`,
    minWidth: "140px",
    right: true,
  },
  {
    name: <ColTitle name="30D Volume" />,
    selector: (row) => row.thirty_day_volume,
    sortable: true,
    format: (row) => `$${row.thirty_day_volume?.toLocaleString()}`,
    minWidth: "140px",
    right: true,
  },
];
export const DexesOveriew = () => {
  const [sortBy, setSortBy] = useState("volumeLast24HoursUsd");
  const [isDescending, setIsDescending] = useState(true);
  const { dexData, loading } = useGetDuneData();
  const handleSorting = (val) => {
    if (sortBy === val) {
      setIsDescending(!isDescending);
    } else {
      setSortBy(val);
    }
  };

  if (loading) {
    <div className="w-full flex items-center justify-center flex-grow">
      <div className="flex items-center flex-col">
        <Loader />
        <div className="text-white/70 text-sm">Loading Data...</div>
      </div>
    </div>;
  }
  if (!dexData)
    return (
      <div className="w-full  px-5 flex flex-col h-[225px] 2xl:h-[calc((60vh-160px)/2)] 2xl:min-h-[225px]">
        <div className="oswald text-xl ">DEXES OVERVIEW</div>
        <div className="flex items-center text-center justify-center w-full h-[calc(100%-50px)] border border-neutral-300/20 rounded-md text-xl">
          Data is on the way. <br />
          Brace for impact.
        </div>
      </div>
    );
  return (
    <div className="w-full h-[calc(70vh-160px)] 2xl:h-[calc(60vh-160px)] 2xl:min-h-[450px] px-5 flex flex-col">
      <div className="oswald text-xl ">DEX Overview</div>
      <DataTable
        columns={columns}
        data={dexData}
        pagination
        fixedHeader={true}
        theme="armyTheme"
        paginationPerPage={50}
        paginationRowsPerPageOptions={[50, 100, 250]}
      />
    </div>
  );
};
